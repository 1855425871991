import axios from 'axios'
import { fetchGet, fetchPost, fetchPostPic, fetchGetMess} from './index'
import {
    CbuWsCategory,
    CentralRegionData,
    PlanningSimulationStatData,
    FcInputTypeData,
    FleetData,
    FcInputDataParam,
    TopicData,
    AdjReleaseType,
    AdjStatResponseData,
    AdjAfterClosingResponseData,
    AdjLabelResponseData,
    AdjustmentInfoResponse,
    WsDataNumberInfo,
    KufriInfo
} from '@/views/Planning/type'

// 获取scenrio列表
export const getScenrioList = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/scenrio/list', {params})
}
// 删除scenrio
export const removeScenrio = (params: any): Promise<any> => {
    return fetchPost(`/pcapi/scenrio/delete/${params}`)
}
// 修改scenrio activeStatus
export const updateScenrioActive = (params = {}): Promise<any> => {
    return fetchPost(`/pcapi/scenrio/updateActiveStatus`, params)
}
// 保存scenrio列表
export const saveScenrio = (params = {}): Promise<any> => {
    return fetchPost('/pcapi/scenrio/save', params)
}
// homePage获取kufri
export const getKufriList = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/planning/basicInfo/getKufri', {params})
}
// homePage获取planningYear
export const getPlanningYear = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/planning/basicInfo/getPlanningYear', {params})
}
// bu homePage获取planningYear
export const getGuidelinePlanningYear = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/planning/basicInfo/getGuideLinePlanningYear', {params})
}
// planning删除
export const removePlanning = (params: number): Promise<any> => {
    return fetchPost('/pcapi/planning/basicInfo/delete/' + params)
}
// planning锁定/解锁
export const lockPlanning = (params = {}): Promise<any> => {
    return fetchPost('/pcapi/planning/basicInfo/lock', params)
}
// planning新建
export const createPlanning = (params = {}): Promise<any> => {
    return fetchPost('/pcapi/planning/basicInfo/save', params)
}
// 下载FM导入模板
export const downloadFmWsTemplate = (planningId: string) => {
    return axios.get(`/pcapi/planning/basicInfo/template/${planningId}`, {
        responseType: "blob"
    })
}
// 导出FM
export const exportFmWs = (make: string, year: string) => {
    return axios.get(`/pcapi/planning/basicInfo/export?make=${make}&year=${year}`, {
        responseType: "blob"
    })
}
// planning上传FM
export const uploadPlanningFm = (params = {}, make: string, year: string, config = {}): Promise<any> => {
    return axios.post(`/pcapi/planning/basicInfo/import/${make}/${year}`, params, {
        ...config,
        responseType: "blob"
    })
}
// homePage 查询是否上传过FM
export const getQueryDataNumber = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/planning/basicInfo/check/queryDataNumber', {params})
}

/** Simulation */
// 获取baseList
export const getBaseList = (params = {}): Promise<string[]> => {
    return fetchGetMess('/pcapi/planning/basicInfo/baseList', {params})
}
// 保存simulation
export const saveSimulation = (params = {}): Promise<any> => {
    return fetchPost('/pcapi/planning/basicInfo/saveSimulation', params)
}
// update simulation plan kufriMonth is kufri latest version
export const lockSimulation = (params = {}): Promise<any> => {
    return fetchPost('/pcapi/planning/basicInfo/lockSimulation', params)
}

/** Data Preparation */

// 获取最新Kufri版本
export const getLatestKufri = (): Promise<string[]> => {
    return fetchGet('/pcapi/planning/cbuws/getKufriList')
}

// 获取CBU WS信息
export const getCbuWsInfo = (planningId: number, category: CbuWsCategory) => {
    return fetchGet('/pcapi/planning/cbuws/getKufriList', { params: { planningId, category } })
}

// 获取CBU WS上传信息
export const getCbuWsUploadInfo = (make: string, kufriVersion: string): Promise<WsDataNumberInfo[]> => {
  return fetchGet('/pcapi/planning/cbuws/check/dataNumber', { params: { make, kufriVersion } })
}

// 导入CBU WS信息
export const importCbuWs = (make: string, year: string, planningId: number, category: CbuWsCategory, kufriVersion: string, params: FormData, config = {}) => {
    return axios.post(`/pcapi/planning/cbuws/import/${make}/${year}/${kufriVersion}/${planningId}/${category}`, params, {
        ...config,
        responseType: "blob"
    })
}

// 导出CBU WS信息
export const exportCbuWs = (planningId: number, category: CbuWsCategory) => {
    return axios.get(`/pcapi/planning/cbuws/export/${planningId}/${category}`, {
        responseType: "blob"
    })
}

// 下载CBU WS导入模板
export const downloadCbuWsTemplate = (category: CbuWsCategory) => {
    return axios.get(`/pcapi/planning/cbuws/template/${category}`, {
        responseType: "blob"
    })
}

/**guideline */

// reopen时获取bulist
export const getReopenBuList = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/planning/bottomUp/getReopenBuList', {params})
}
// 提交reopen
export const updateReopenBus = (params = {}): Promise<any> => {
    return fetchPost('/pcapi/planning/bottomUp/updateReopenBus', params)
}
// guideline获取数据
export const getGuidelineList = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/planning/guideLine/info', {params})
}
// guideline页面刷新kfuri
export const refreshKufri = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/planning/guideLine/refresh', {params})
}

// guideline页面获取年份
export const getGuidelineYear = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/planning/basicInfo/getGuideLineYear', {params})
}

// guideline页面 set bottom year
export const setBottomUpYear = (params = {}): Promise<any> => {
    return fetchPost('/pcapi/planning/basicInfo/setBottomUpYear', params)
}

// guideline页面 save
export const saveGuideline = (params = {}): Promise<any> => {
    return fetchPost('/pcapi/planning/guideLine/save', params)
}

// guideline页面点击forecast 
export const refreshBudget = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/planning/guideLine/refreshBudget', {params})
}

/**Bottom Up */

// Bottom Up获取数据
export const getBottomUpList = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/planning/bottomUp/info', {params})
}
// Bottom Up页面刷新kfuri
export const refreshKufriBottomUp = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/planning/bottomUp/refresh', {params})
}

// Bottom Up页面 save
export const saveBottomUp = (params = {}): Promise<any> => {
    return fetchPost('/pcapi/planning/bottomUp/save', params)
}

// bottomUp页面点击forecast 
export const refreshBudgetBu = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/planning/bottomUp/refreshBudget', {params})
}

/** Planning & Simulation */

// 获取统计数据
export const getTotalData = (planningId: number, kufriVersion: string): Promise<PlanningSimulationStatData> => {
    return fetchGet('/pcapi/planning/common/getTotalData', { params: { planningId, kufriVersion} })
}

// C+R 获取品牌列表
export const getCrBrandList = (planningId: number, kufriVersion: string): Promise<string[]> => {
    return fetchGet('/pcapi/planning/cr/getBrandList', { params: { planningId, kufriVersion} })
}
// C+R 获取列表详细数据
export const getCrInfo = (brandList: string[], planningId: number, kufriVersion: string): Promise<CentralRegionData[]> => {
    return fetchGet('/pcapi/planning/cr/info', { params: { brandList, planningId, kufriVersion} })
}

// Fleet & UC & Bidding & Floor Plan 类型
export const getFcInputTypeList = (): Promise<FcInputTypeData[]> => {
    return fetchGet('/pcapi/planning/fcinput/queryTypeList')
}
// Fleet & UC & Bidding & Floor Plan 获取品牌列表
export const getFcInputBrandList = (planningId: number, kufriVersion: string, planningTypeId: number): Promise<string[]> => {
    return fetchGet('/pcapi/planning/fcinput/queryBrandList', { params: { planningId, kufriVersion, planningTypeId } })
}
// Fleet & UC & Bidding & Floor Plan 后去列表详细数据
export const getFcInputData = (brandList: string[], planningId: number, kufriVersion: string, planningTypeId: number): Promise<FleetData[]> => {
    return fetchPost('/pcapi/planning/fcinput/queryFcInputData', {
        brandList,
        kufriVersion,
        planningId,
        planningTypeId
    })
}

// Fleet & UC & Bidding & Floor Plan 保存
export const saveFcInputData = (data: FcInputDataParam[]) => {
    return fetchPost('/pcapi/planning/fcinput/save', data)
}

// Fleet & UC & Bidding & Floor Plan 获取proposal数据
export const getFcInputProposalData = (planningId: number, month: string, typeId: number, kufriVersion: string): Promise<Record<string, number>> => {
    return fetchGet('/pcapi/planning/fcinput/getPorposeDataByMonth', { params: { planningId, month, typeId, kufriVersion } })
}

// Adjustment & Release 获取topic列表
export const getArTopicList = (planningId: number): Promise<TopicData[]> => {
    return fetchGet('/pcapi/planning/ar/topicList', { params: { planningId } })
}

// Adjustment & Release 获取品牌列表
export const getArBrandList = (planningId: number, kufriVersion: string, planningType: AdjReleaseType): Promise<string[]> => {
  return fetchGet('/pcapi/planning/ar/brandList', { params: { planningId, kufriVersion, planningType } })
}

// Adjustment & Release 获取统计数据
export const reportByTopicAndAdjustment = (planningId: number, kufriVersion: string): Promise<AdjStatResponseData> => {
  return fetchGet('/pcapi/planning/ar/reportByTopicAndAdjustment', { params: { planningId, kufriVersion } })
}

// Adjustment & Release 获取Adj. After Closing列表详细数据
export const getAfterClosingReport = (planningId: number, brandlist: string[]): Promise<AdjAfterClosingResponseData[]> => {
  return fetchGet('/pcapi/planning/ar/afterClosingReport', { params: { planningId, brandlist } })
}

// Adjustment & Release 获取Adjustment列表
export const getAdjLabelList = (planningId: number, kufriVersion: string): Promise<AdjLabelResponseData[]> => {
  return fetchGet('/pcapi/planning/ar/adjLabelList', { params: { planningId, kufriVersion } })
}

// Adjustment & Release 获取Adjustment详细信息
export const getAdjustmentInfo = (brandList: string[], headerId: number): Promise<AdjustmentInfoResponse> => {
  return fetchGet('/pcapi/planning/ar/adjustmentInfo', { params: { brandList, headerId } })
}

// Adjustment & Release 获取Release详细信息
export const getReleaseInfo = (brandList: string[], planningId: number, kufriVersion: string): Promise<AdjustmentInfoResponse> => {
  return fetchGet('/pcapi/planning/ar/releaseInfo', { params: { brandList, planningId, kufriVersion } })
}

// Adjustment & Release 新增Adjustment时获取AdjustmentNo
export const addAdjustmnetLabel = (planningId: number, kufriVersion: string, adjustmentNos: string[]): Promise<AdjustmentInfoResponse[]> => {
  return fetchPost('/pcapi/planning/ar/addAdjustmnetLabel', {}, { params: {planningId, kufriVersion, adjustmentNos } })
}

// Adjustment & Release 新增Adjustment时获取数据
export const newAdjustmentData = (brandList: string[], planningId: number, kufriVersion: string, adjustmentNo?: string): Promise<AdjustmentInfoResponse[]> => {
  return fetchGet('/pcapi/planning/ar/newAdjustmentData', { params: { brandList, planningId, kufriVersion, adjustmentNo } })
}

// Adjustment & Release 保存Adjustment数据
export const saveAdjustmentData = (data: object): Promise<number> => {
  return fetchPost('/pcapi/planning/ar/save', data)
}

//finalView 获取表1数据
export const getReportOverview = (params = {}): Promise<any> => {
  return fetchGetMess('/pcapi/planning/finalReport/overview', {params})
}

//finalView 获取表2查询条件options
export const getReportStructureTree = (): Promise<any> => {
  return fetchGet('/pcapi/report/structure/tree')
}

//finalView 获取表2数据
export const getReportTable2 = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/planning/finalReport/report', {params})
}

//finalView 获取表3数据
export const getReportMonth = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/planning/finalReport/month', {params})
}

//get current dealer stock data
export const getCurrentDealerStock = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/current', {params})
}

// dealer stock current save avgSi
export const saveAvgSi = (data: object): Promise<number> => {
    return fetchPost('/pcapi/current/saveAvgSi', data)
}

// dealer stock current save amount
export const saveAmount = (data: object): Promise<number> => {
    return fetchPost('/pcapi/current/saveAmount', data)
}

//get future dealer stock data
export const getfutureDealerStock = (params = {}): Promise<any> => {
    return fetchGetMess('/pcapi/future', {params})
}

// 导出
// 导出Ajustment
export const exportAjustment = (data: object) => {
    return axios.post(`/pcapi/planning/ar/exportAdjExcel`, {}, {
        params: data,
        responseType: "blob"
    })
}
// 导出Release 
export const exportRelease = (data: object) => {
    return axios.post(`/pcapi/planning/ar/exportRelExcel`,{}, {
        params: data,
        responseType: "blob"
    })
}
// 导出FcInput
export const exportFcInput= (data: object) => {
    return axios.post(`/pcapi/planning/fcinput/exportExcel`,data, {
        responseType: "blob"
    })
}
// 导出C+R
export const exportCr= (data: object) => {
    return axios.post(`/pcapi/planning/cr/exportExcel`,{}, {
        params: data,
        responseType: "blob"
    })
}
// 导出final report
export const exportFinal= (data: object) => {
    return axios.post(`/pcapi/planning/finalReport/exportExcel?`, {}, {
        params: data,
        responseType: "blob"
    })
}

// 轮训查询simulation初始化状态
export const initProcess= (params: object) => {
    return fetchGetMess(`/pcapi/planning/kufri/init/process?`, {params})
}

// dealer stock
export const nextYearData= (params: object) => {
    return fetchGetMess(`/pcapi/planning/basicInfo/nextYearData`, {params})
}

// dealer stock
export const resetCurrent= (params: object) => {
    return fetchGetMess(`/pcapi/current/reset`, {params})
}

// dealer stock current 导出
export const currentExport= (params: object) => {
    return axios.get(`/pcapi/current/export`, {
        params: params,
        responseType: "blob"
    })
}

// dealer stock feature 导出
export const featureExport= (params: object) => {
    return axios.post(`/pcapi/exportFutureExcel?`, {}, {
        params: params,
        responseType: "blob"
    })
}

export const kufriInfo = (planningId: number): Promise<KufriInfo[]> => {
    return fetchGet('/pcapi/planning/kufri/info', {
      params: {
        planningId
      }
    })
}

export const exportKufri = (make: string, version: string, year: string, type: 'RT'|'WS'|'DS') => {
    return axios.get(`/pcapi/planning/kufri/export/${type}`, {
        params: {
          make,
          version,
          year
        },
        responseType: "blob"
    })
}