export const monthes = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

export const monthesEn = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']

export function monthNumberToEn(month: string) {
  const index = monthes.indexOf(month)
  return index === -1 ? undefined : monthesEn[index]
}

export function monthEnToNumber(month: string, short = false) {
  const index = monthesEn.indexOf(month)
  return index === -1 ? undefined : (short ? monthes[index].replace(/^0/, '') : monthes[index])
}

export function numberSubWithPrecision(n: string | number, p?: number, m?: number, showthousands = false) {
  if (typeof n === 'number') {
    n = n.toString()
  }
  if (showthousands) {
    n = n.replace(/,/g, '')
  }
  let sign = ''
  if (n[0] === '-') {
    sign = '-'
    n = n.slice(1)
  }
  let i = n.search(/[^.0-9]/)
  if (i !== -1) {
    n = n.slice(0, i)
  }
  i = n.indexOf('.')
  let integer = ''
  let point = ''
  if (i === -1) {
    integer = n
  } else {
    integer = n.slice(0, i + 1)
    point = n.slice(i + 1).replace('.', '')
  }
  if (m !== undefined) {
    integer = integer.slice(0, integer[integer.length - 1] === '.' ? m + 1 : m)
  }
  if (p !== undefined) {
    point = point.slice(0, p)
  }
  if (showthousands) {
    integer = integer.replace(/(\d)(?=(?:\d{3}\.?)+$)/g, '$1,')
  }
  return point === '' ? `${sign}${integer}` : `${sign}${integer}${point}`
}

type Data = Partial<Record<string, Data[]|unknown>>

export function forEachRecursive<T extends Data>(items: T[], callback: (item: T, index: number) => any, childrenKey = 'children') {
  items?.forEach((item: T, index: number) => {
    callback(item, index)
    if (item[childrenKey]) {
      forEachRecursive(item[childrenKey] as T[], callback, childrenKey)
    }
  })
}