
import { defineComponent, watch, ref, onMounted } from 'vue'
import type { PropType } from 'vue'
import GC from '@grapecity/spread-sheets'
import Decimal from '@/utils/closing/decimal'
import useWait from '@/utils/payment/useWait'

/**
 * 表格列头类型
 */
type Column = {
  [key: string]: unknown;
  title: string;
  dataIndex?: string;
  formatter?: string | GC.Spread.Formatter.GeneralFormatter	;
  width?: number;
  align?: 'left'|'center'|'right';
  fixed?: 'left'|'right';
  locked?: boolean;
  style?: Record<string, unknown>;
  headerStyle?: Record<string, unknown>;
  customRender?: GC.Spread.Sheets.CellTypes.Base;
  children?: Column[];
}

export type Columns = Column[]

/**
 * 获取列头行数
 */
function columnsRows(columns?: Columns) {
  if (columns === undefined || columns.length === 0) {
    return 0
  } else {
    let childrenDepth = 0
    columns.forEach(column => {
      const childDepth = columnsRows(column.children)
      if ( childDepth > childrenDepth) {
        childrenDepth = childDepth
      }
    })
    return childrenDepth + 1
  }
}
/**
 * 获取列头树叶节点
 */
function columnsLeafs(columns?: Columns | Column) {
  if (!Array.isArray(columns)) {
    columns = columns?.children
  }
  let leafs: Columns = []
  columns?.forEach(column => {
    if (column.children === undefined || column.children.length === 0) {
      leafs.push(column)
    } else {
      const childrenLeafs = columnsLeafs(column.children)
      leafs = leafs.concat(childrenLeafs)
    }
  })
  return leafs
}

function paintHeaderColumn(sheet: GC.Spread.Sheets.Worksheet, columns: Columns, rows: number, row = 0, col = 0) {
  columns.forEach(column => {
    const childrenColumnsNum = columnsLeafs(column).length
    sheet.addSpan(row, col, childrenColumnsNum ? 1 : rows - row, childrenColumnsNum ? childrenColumnsNum : 1,
      GC.Spread.Sheets.SheetArea.colHeader)
    sheet.setValue(row, col, column.title, GC.Spread.Sheets.SheetArea.colHeader)
    if (column.customRender) {
      sheet.setCellType(row, col, column.customRender, GC.Spread.Sheets.SheetArea.colHeader)
    }
    if (column.headerStyle) {
      const style = new GC.Spread.Sheets.Style()
      Object.assign(style, column.headerStyle)
      sheet.setStyle(row, col, style, GC.Spread.Sheets.SheetArea.colHeader)
    }
    if (column.children) {
      paintHeaderColumn(sheet, column.children, rows, row + 1, col)
    }
    col += (childrenColumnsNum ? childrenColumnsNum : 1)
  })
}

export class FormatPercentage extends GC.Spread.Formatter.GeneralFormatter	 {
  constructor() {
    super('', 'percentage')
  }

  format(value: object) {
    if (value === null || value === undefined) {
      return ''
    }
    if (value.toString() === '') {
      return ''
    } else {
      return `${(new Decimal(value.toString())).round(2)}%`
    }
  }
}

export default defineComponent({
  props: {
    id: {
      type: String
    },
    columns: {
      type: Array as PropType<Columns>,
      default: () => []
    },
    dataSource: {
      type: Array as PropType<unknown[]>,
      default: () => []
    },
    frozenRowCount: {
      type: Number,
      default: 0
    },
    cellRender: {
      type: Function as PropType<(row: number, column: string, cell: GC.Spread.Sheets.CellRange) => void>
    },
    expandRows: {
      type: Array as PropType<number[]>,
      default: () => []
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'edit-ended',
    'edit-starting',
    'value-changed',
    'clipboard-pasting',
    'range-changed'
  ],
  setup(props, context) {
    const wait = useWait()

    onMounted(() => {
      wait.toRun?.()
    })

    const spread = ref<GC.Spread.Sheets.Workbook>()

    const paint = (resetRowExpand: boolean) => {
      if (!spread.value) {
        const container = document.getElementById(props.id as string)
        if (!container) {
          return
        }
        spread.value = new GC.Spread.Sheets.Workbook(container)

        const sheet = spread.value.getActiveSheet()
        
        sheet.bind(GC.Spread.Sheets.Events.EditEnded, (event: any, data: any) => {
          context.emit('edit-ended', event, data)
        })

        sheet.bind(GC.Spread.Sheets.Events.EditStarting, (event: any, data: any) => {
          context.emit('edit-starting', event, data)
        })

        sheet.bind(GC.Spread.Sheets.Events.ClipboardPasting, (event: any, data: any) => {
          context.emit('clipboard-pasting', event, data)
        })

        sheet.bind(GC.Spread.Sheets.Events.ValueChanged, (event: any, data: any) => {
          context.emit('value-changed', event, data)
        })

        sheet.bind(GC.Spread.Sheets.Events.RangeChanged, (event: any, data: any) => {
          context.emit('range-changed', event, data)
        })
      }

      const sheet = spread.value.getActiveSheet()
      spread.value.suspendPaint();

      //填充铺满整个canvas
      spread.value.options.scrollbarMaxAlign = true
      spread.value.options.scrollByPixel = true

      spread.value.options.tabNavigationVisible = false
      spread.value.options.tabStripVisible = false

      // 粘贴时跳过折叠行
      spread.value.options.pasteSkipInvisibleRange = true

      // 滚动条样式
      spread.value.options.scrollbarAppearance = GC.Spread.Sheets.ScrollbarAppearance.mobile

      // 设置多少行和列
      // sheet.setRowCount(data.length, GC.Spread.Sheets.SheetArea.viewport)

      //禁止缩放
      spread.value.options.allowUserZoom = false
      // 必须加上这两个才能折叠展开
      sheet.options.protectionOptions.allowOutlineRows = true
      sheet.options.protectionOptions.allowOutlineColumns = true

      // 设置整个表格不能插入， 删除行列
      // allowInsertRows不允许插入行，allowInsertColumns不允许插入列
      // allowDeleteRows不允许删除行, allowDeleteColumns不允许删除列
      sheet.options.protectionOptions.allowInsertRows = false
      sheet.options.protectionOptions.allowInsertColumns = false
      sheet.options.protectionOptions.allowDeleteRows = false
      sheet.options.protectionOptions.allowDeleteColumns = false
      sheet.options.protectionOptions.allowEditObjects = true


      //禁止拖动填充
      spread.value.options.allowUserDragFill = false
      spread.value.options.allowUserDragDrop = false

      spread.value.options.allowCopyPasteExcelStyle = false


      // 设置隐藏头和列
      sheet.options.rowHeaderVisible = false
      // 要设置允保护，不允许的才能生效
      sheet.options.isProtected = true
      // 内部复制，禁止复制样式
      sheet.options.clipBoardOptions = GC.Spread.Sheets.ClipboardPasteOptions.values

      const defaultStyle = new GC.Spread.Sheets.Style()
      //默认对其方式
      defaultStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.center
      defaultStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center
      defaultStyle.borderBottom = new GC.Spread.Sheets.LineBorder('gray', GC.Spread.Sheets.LineStyle.thin)
      defaultStyle.borderTop = new GC.Spread.Sheets.LineBorder('gray', GC.Spread.Sheets.LineStyle.thin)
      defaultStyle.borderLeft = new GC.Spread.Sheets.LineBorder('gray', GC.Spread.Sheets.LineStyle.thin)
      defaultStyle.borderRight = new GC.Spread.Sheets.LineBorder('gray', GC.Spread.Sheets.LineStyle.thin)
      defaultStyle.locked = props.locked
      sheet.setDefaultStyle(defaultStyle)

      sheet.autoGenerateColumns = false
      const rows = columnsRows(props.columns)
      sheet.setRowCount(rows, GC.Spread.Sheets.SheetArea.colHeader)
      const indexColumns = columnsLeafs(props.columns)
      sheet.setColumnCount(indexColumns.length)
      paintHeaderColumn(sheet, props.columns, rows)

      sheet.setDataSource(props.dataSource)

      for (let i = 0; i < indexColumns.length; i++) {
        sheet.bindColumn(i, {
          name: indexColumns[i].dataIndex
        })
      }

      for (let i = 0; i < props.dataSource.length; i++) {
        const level = (props.dataSource[i] as {level: number}).level
        const cell = sheet.getCell(i, 0).textIndent(level)
        if (indexColumns?.[0]?.align) {
          cell.hAlign(GC.Spread.Sheets.HorizontalAlign[indexColumns[0].align])
        }
      }

      sheet.setColumnCount(indexColumns.length)

      sheet.outlineColumn.options({
          columnIndex: 0,
          showImage: true,
          showCheckBox: false,
          expandIndicator: require('@/assets/images/increaseIndicator.png'),
          collapseIndicator: require('@/assets/images/decreaseIndicator.png'),
          maxLevel: 3
      })
      
      sheet.showRowOutline(false)

      // 冻结列
      let fixedLeftColumnNum = 0
      for (let i = 0; i < indexColumns.length; i++) {
        if (indexColumns[i].fixed === 'left') {
          fixedLeftColumnNum++
        } else {
          break
        }
      }
      if (fixedLeftColumnNum) {
        sheet.frozenColumnCount(fixedLeftColumnNum)
      }
      let fixedRightColumnNum = 0
      for (let i = indexColumns.length - 1; i >= 0; i--) {
        if (indexColumns[i].fixed === 'right') {
          fixedRightColumnNum++
        } else {
          break
        }
      }
      if (fixedRightColumnNum) {
        sheet.frozenTrailingColumnCount(fixedRightColumnNum)
      }

      for (let i = 0; i < indexColumns.length; i++) {
        if (indexColumns[i].width) {
          sheet.setColumnWidth(i, indexColumns[i].width as number)
        }
        const columnCells = sheet.getRange(-1, i, -1, i)
        if (indexColumns[i].style) {
          const style = new GC.Spread.Sheets.Style()
          Object.assign(style, indexColumns[i].style)
          sheet.setStyle(-1, i, style, GC.Spread.Sheets.SheetArea.viewport)
        }
        if (indexColumns[i].align) {
          columnCells.hAlign(GC.Spread.Sheets.HorizontalAlign[indexColumns[i].align as 'left'|'center'|'right'])
        }
        if (indexColumns[i].formatter) {
          columnCells.formatter(indexColumns[i].formatter)
        }
        const locked = indexColumns[i].locked
        if (locked) {
          columnCells.locked(locked)
        }
      }


      for (let row = 0; row < props.dataSource.length; row++) {
        if (resetRowExpand) {
          sheet.rowOutlines.setCollapsed(row, !props.expandRows.includes(row))
        }
        if (props.cellRender) {
          for (let column = 0; column < indexColumns.length; column++) {
            const cell = sheet.getCell(row, column)
            props.cellRender(row, indexColumns[column].dataIndex ?? '', cell)
          }
        }
      }

      if (props.frozenRowCount) {
        sheet.frozenRowCount(props.frozenRowCount)
      }

      sheet.resumePaint()
    }

    watch([() => props.columns, () => props.dataSource], async ([newColumns, newDataSource]: [Columns, unknown[]], [columns, dataSource]: [Columns | undefined, unknown[] | undefined]) => {
      await wait
      if (spread.value && newDataSource !== dataSource) { //数据源整体变化，销毁重建spread对象，避免折叠出现问题
        spread.value.destroy()
        spread.value = undefined
      }
      if (newColumns.length) {
        paint(newDataSource !== dataSource)
      }
    }, {
      immediate: true,
      deep: true
    })

    watch(() => props.expandRows, () => {
      if (!spread.value) {
        return
      }
      const sheet = spread.value.getActiveSheet()
      sheet.suspendPaint()
      for (let row = 0; row < props.dataSource.length; row++) {
        sheet.rowOutlines.setCollapsed(row, !props.expandRows.includes(row))
      }
      sheet.resumePaint()
    })

    watch(() => props.locked, (locked) => {
      if (!spread.value) {
        return
      }
      const sheet = spread.value.getActiveSheet()
      const defaultStyle = sheet.getDefaultStyle()
      defaultStyle.locked = locked
      sheet.setDefaultStyle(defaultStyle)
    })
  }
})
